<template>
  <CDropdown
    inNav
    class="c-header-nav-items theheaderaccount"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink ref="userdropdown">
        <div class="c-avatar dropdown-toggle">
          <CIcon name="cil-user"/>
        </div>
      </CHeaderNavLink>
    </template>

    <CDropdownHeader tag="div" color="light" class="text-center">
      <strong>Mitarbeiter</strong>
    </CDropdownHeader>

    <CDropdownItem disabled>
      <span class="text-center" style="width: 100%;">
        {{ name }}
      </span>
    </CDropdownItem>

    <CDropdownItem disabled style="padding-top: 0;">
      <span class="text-center" style="width: 100%; font-size: 0.6rem;">
        Letzter Login am
        <br>
        {{last_login|moment('DD.MM.YYYY')}}
        um
        {{last_login|moment('HH:mm:ss')}} Uhr
      </span>
    </CDropdownItem>

    <CDropdownDivider/>

    <CDropdownItem to="/profile/password">
      <CIcon name="cil-https" /> Mein Passwort Ändern
    </CDropdownItem>

    <CDropdownItem to="/security/logout" class="text-center" style="display: block;">
      <CIcon name="cil-lock-locked" /> Logout
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import Vue from 'vue'

export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return {
      name: ''
    }
  },
  computed: {
    user: function () {
      return this.$store.getters['security/user']
    },
    last_login () {
      return (this.user ? this.user.last_login : '')
    }
  },
  mounted () {
    this.getName()
  },
  methods: {
    async getName () {
      const mitarbeiterloaded = await this.loadMitarbeiter()
      const curUser = mitarbeiterloaded.data.filter(mitarbeiter => {
        return mitarbeiter.mitarbeiterid === this.user.userid
      })

      this.name = curUser[0].name
    },
    async loadMitarbeiter () {
      return Vue.axios.get('/mitarbeiter/get')
    }
  }
}
</script>

<style lang="scss">
  li.theheaderaccount {
    .c-icon {
      margin-right: 0.3rem;
    }
    a.disabled {
      color: rgba(0, 0, 21, 0.5) !important;
    }
  }
</style>
